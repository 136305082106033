/** @format */
import React, {useEffect, useCallback} from 'react';
import {useRouter} from 'next/router';

import {useApp} from '@context/AppProvider';

function Cms(props) {
	const {cmsdata} = props;
	const router = useRouter();
	const {
		idb: {user},
		showModel,
	} = useApp();
	let _html = '';
	const {blocks} = cmsdata?.raw_data ? JSON.parse(cmsdata?.raw_data) : [];
	if (blocks) {
		for (let index = 0; index < blocks.length; index++) {
			_html += blocks[index];
		}
	}

	switch(true){
		case _html.includes("background-color:#405738 !important;"):
			_html = _html.replace("style=\"background-color:#405738 !important;\"", "style=\"background-color:#006180;\"");
			break;
		case _html.includes("background-color:#99c8cc !important;"):
			_html = _html.replace("style=\"background-color:#99c8cc !important;\"", "style=\"background-color:#006180;\"");
			break;
		case _html.includes("background-color:#fab5b2 !important;"):
			_html = _html.replace("style=\"background-color:#fab5b2 !important;\"", "style=\"background-color:#000000;\"");
			break;
		case _html.includes("background-color:#f0e3d4 !important;"):
			_html = _html.replace("style=\"background-color:#f0e3d4 !important;\"", "style=\"background-color:#006180;\"");
			break;
		default:
			_html = _html;
			break;
	}
	const html = _html.replace('Poppins', 'Lato');

	const inititalizeCms = useCallback(() => {
		try {
			let styleCss = '';
			if (cmsdata && cmsdata.raw_data) {
				const {style, blocks, inlineStyle} = cmsdata.raw_data
					? JSON.parse(cmsdata?.raw_data)
					: [];

				const styleIndex = style.findIndex((s) => s.includes('https://portal.betterthat.com/public/assets/editor/css/custom.css'));
				style[styleIndex] = style[styleIndex].replace('https://portal.betterthat.com/public/assets/editor/css/custom.css', '/custom.css');

				if (blocks) {
					for (let j = 0; j < style.length; j++) {
						if (
							!(
								style[j].match('bootstrap.min.css') ||
								style[j].match('froala_blocks.min.css')
							)
						) {
							styleCss += style[j];
						}
					}
					document
						.getElementById('dynamicstyle')
						?.insertAdjacentHTML('beforeend', inlineStyle);
					document.getElementById('dynamicstyle').innerHTML += styleCss;
				}
			}
			if (cmsdata && cmsdata.custom_css) {
				let css = cmsdata.custom_css;
				if (css) {
					document
						.getElementById('dynamicstyle')
						?.insertAdjacentHTML('beforeend', `<style>${css}</style>`);
				}
			}
		} catch (e) {
			return e.mesage;
		}
	}, [cmsdata]);

	useEffect(() => {
		inititalizeCms();
	}, [inititalizeCms]);

	const handleAnchorClick = useCallback(
		e => {
			e.preventDefault();
			if (e.target.id && e.target.id === 'nomiate') {
				if (user) {
					router.push('/my-good-cause');
				} else {
					showModel('login');
				}
			} else {
				const targetLink = e.target.closest('a');
				if (!targetLink) return;
				const pathname = targetLink.href;
				if (pathname.match('register')) {
					showModel('register');
				} else {
					router.push(pathname);
				}
			}
		},
		[router, showModel, user],
	);

	return (
		<>
			<div
				className='foral-editor'
				id='content-area'
				onClick={handleAnchorClick}
				onKeyPress={handleAnchorClick}
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			></div>
		</>
	);
}

export default Cms;
